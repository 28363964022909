// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  icmRedirect: 'https://www.campaigns.11posts.com',
  exampleCampaign: '3ce74b11-c4dd-4f5d-8a3d-f2bfb0ffe907',
  platform_id: '11posts',
  platform_logo: '/assets/png/11posts.png',
  platform_custom_validated_account: 'true',
  platform_icon: '/assets/img/ico/11posts.ico',
  paddle_vendor: 108473,
  websocketChatBrief: 'wss://rcps3p5rn7.execute-api.eu-west-1.amazonaws.com/prod',
  binflu_emails: [
    'pablo@binfluencer.es', 'sara.cristobal.latam@binfluencer.io', 'gabriela.eganez@binfluencer.io',
    'alejandro.iglesias@binfluencer.io', 'bastian.rozas@binfluencer.io', 'sara.cristobal@binfluencer.io'
  ],
  platform_register_validated_account:
      'true',
  platform_register_company_logo:
      '11posts',
  aws_resources: {
    // Region for AWS Resources
    region: 'eu-west-1',

    // API key
    api_key: 'tADbyYfwDGIl6Rh8UweV4o1YF3E4wzs3ba39nTH6',

    // Cognito user pool
    cognito: {
      identity_pool_id: 'eu-west-1:06175148-9a3c-4714-b50a-14e26c3b3f39',
      user_pool_id: 'eu-west-1_RgFBf87hq',
      client_id: '55t0819cj8olbf3i05i9101cqs',
      endpoint: 'https://cognito-idp.eu-west-1.amazonaws.com',
      identity_endpoint: 'cognito-identity.eu-west-1.amazonaws.com',
    },

    // Lambda endpoint
    lambda_endpoint: {
      api:
        'https://api.binfluencer.io/',
    },

    // S3
    s3: {
      endpoint: 'https://s3-eu-west-1.amazonaws.com/',
      buckets: {
        profile_image: 'prod-pax-customer-profile-image',
        company_logo: 'prod-pax-brand-logo',
        presentation_logo: 'prod-pax-presentation-logo',
        inspirational_pictures: 'prod-pax-inspirational-pictures',
        report_insight_images : 'prod-pax-report-insight-images',
        proposals: 'prod-pax-progress-proposals',
        stories: 'prod-pax-stories-instagram',
        dashboards_logo: 'prod-pax-dashboard-logo',
        user_group_logo: 'prod-pax-company-logo-image',
      }
    }
  },
  intercom: {
    config: {
      appId: 'oljxdm76',
      updateOnRouterChange: true,
    },
  },
  permissions: {
    search: ['VIEW_SEARCH'],
    influencer: ['VIEW_SEARCH'],
    influencerDetails: ['VIEW_SEARCH'],
    campaigns: ['LIST_CAMPAIGNS', 'VIEW_CAMPAIGN'],
    campaignDetails: ['LIST_CAMPAIGNS', 'VIEW_CAMPAIGN'],
    brief: ['VIEW_BRIEF'],
    progress: ['VIEW_PROGRESS'],
    report: ['VIEW_REPORT'],
    admin: ['ADMIN_ACCESS_OTHER_CAMPAIGNS', 'ADMIN_ACCOUNT_MANAGEMENT', 'ADMIN_ACTIVE_CAMPAIGNS', 'ADMIN_HEADER', 'ADMIN_LIST_USERS', 'ADMIN_REQUESTS'],
  },
};
